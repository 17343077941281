"use client";

import { Container } from "@/app/[locale]/components/shared/Container";
import { H1, P } from "@/app/[locale]/components/shared/tags/TextElements";
import { usePathname } from "@/navigation";

export default function NotFound() {
  const path = usePathname();

  return (
    <Container>
      <div className="flex min-h-screen items-center justify-center">
        <div className="card mx-auto w-full max-w-xl bg-neutral p-4">
          <H1 extraClasses="mb-4">Page not found</H1>
          <P>
            Try reloading the page.
            <br />
            If the issue persists please contact hi@kietzee.com
          </P>
        </div>
      </div>
    </Container>
  );
}
