import { getRequestConfig } from "next-intl/server";
import { Pathnames } from "next-intl/navigation";

export const pathnames = {
  "/": "/",
} satisfies Pathnames<typeof i18n.locales>;

export default getRequestConfig(async ({ locale }) => ({
  messages: (await import(`../messages/${locale}.json`)).default,
}));

export const i18n = {
  defaultLocale: "en",
  locales: ["en", "de"],
};

export type AppPathnames = keyof typeof pathnames;
