import { Link } from "@/navigation";
import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  extraClasses?: string;
};

export const H1: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <h1 className={clsx("text-5xl text-primary", extraClasses)}>{children}</h1>
  );
};

export const H2: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <h2 className={clsx("text-4xl text-primary", extraClasses)}>{children}</h2>
  );
};

export const H3: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <h3 className={clsx("text-3xl text-primary", extraClasses)}>{children}</h3>
  );
};

export const H4: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <h4 className={clsx("text-2xl text-secondary", extraClasses)}>
      {children}
    </h4>
  );
};

export const H5: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <h5 className={clsx("text-xl text-secondary", extraClasses)}>{children}</h5>
  );
};

export const H6: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <h6 className={clsx("text-lg text-secondary", extraClasses)}>{children}</h6>
  );
};

export const P: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <p className={clsx("text-base text-base-300", extraClasses)}>{children}</p>
  );
};

export const Small: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <small className={clsx("text-sm text-base-300", extraClasses)}>
      {children}
    </small>
  );
};

export const Strong: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return <strong className={extraClasses}>{children}</strong>;
};

export const Em: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return <em className={clsx("italic", extraClasses)}>{children}</em>;
};

export const Blockquote: React.FC<Props> = ({
  children,
  extraClasses = "",
}) => {
  return (
    <blockquote
      className={clsx("border-l-4 pl-4 italic text-base-200", extraClasses)}
    >
      {children}
    </blockquote>
  );
};

export const Span: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <span className={clsx("text-base text-base-300", extraClasses)}>
      {children}
    </span>
  );
};

export const A: React.FC<Props & { href: string }> = ({
  children,
  extraClasses = "",
  href,
}) => {
  return (
    <a href={href} className={clsx("text-secondary", extraClasses)}>
      {children}
    </a>
  );
};

export const ALink: React.FC<Props & { href: string }> = ({
  children,
  extraClasses = "",
  href,
}) => {
  return (
    <Link href={href} className={clsx("text-secondary", extraClasses)}>
      {children}
    </Link>
  );
};

export const Abbr: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <abbr className={clsx("dotted underline", extraClasses)}>{children}</abbr>
  );
};

export const Code: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return (
    <code className={clsx("rounded bg-base-100 p-1 text-sm", extraClasses)}>
      {children}
    </code>
  );
};

export const Q: React.FC<Props> = ({ children, extraClasses = "" }) => {
  return <q className={clsx("italic", extraClasses)}>{children}</q>;
};
